var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"plate"}},[_c('div',{staticClass:"columns"},[_vm._m(0),_c('div',{staticClass:"column is-narrow"},[_c('p',{staticClass:"title is-6"},[(_vm.changes)?_c('span',{staticClass:"tag is-warning"},[_vm._v(_vm._s(_vm.changes)+" change(s) reported")]):(_vm.type96 || _vm.voidVrm || _vm.noDvlaRegistration)?_c('span',{staticClass:"has-text-danger"},[_vm._v("Warning")]):_c('span',{staticClass:"has-text-success"},[_vm._v("None")])])])]),(_vm.type96)?_c('div',{staticClass:"box"},[_vm._m(1)]):_vm._e(),(_vm.voidVrm)?_c('div',{staticClass:"box"},[_vm._m(2)]):_vm._e(),(_vm.noDvlaRegistration)?_c('div',{staticClass:"box"},[_vm._m(3)]):_vm._e(),(_vm.changes)?_c('div',{staticClass:"box"},[_c('table',{staticClass:"table is-fullwidth is-striped"},[_vm._m(4),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',{attrs:{"width":"50%"}},[_c('div',{staticClass:"columns is-vcentered is-mobile"},[_c('div',{staticClass:"column is-narrow"},[_c('button',{staticClass:"button has-shadow is-size-7-mobile"},[_vm._v(" "+_vm._s(item.previousVRM)+" ")])]),_vm._m(5,true),_c('div',{staticClass:"column is-narrow"},[_c('button',{staticClass:"button has-shadow is-size-7-mobile"},[_vm._v(" "+_vm._s(item.currentVRM)+" ")])])])]),_c('td',{attrs:{"width":"50%"}},[_vm._v(_vm._s(_vm.formatDate(item.dateOfReceipt)))])])}),0)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('p',{staticClass:"title has-text-grey-light is-6"},[_vm._v("Numberplate changes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title is-6 has-text-danger"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-exclamation-triangle"})]),_vm._v(" There is an issue with this check. Phone 0870 9059 095 to speak to an agent ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title is-6 has-text-danger"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-exclamation-triangle"})]),_vm._v(" Void VRM - check the new plate. The data guarantee is not applicable to this check. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title is-6 has-text-danger"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-exclamation-triangle"})]),_vm._v(" There is no DVLA registration for this vehicle. The data guarantee is not applicable to this check. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',[_vm._v("Plate change")]),_c('td',[_vm._v("Date of change")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-narrow is-paddingless"},[_c('span',{staticClass:"icon has-text-info is-size-7-mobile"},[_c('i',{staticClass:"fal fa-arrow-right"})])])
}]

export { render, staticRenderFns }