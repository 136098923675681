<template>
  <div id="plate">
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">Numberplate changes</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span class="tag is-warning" v-if="changes"
            >{{ changes }} change(s) reported</span
          >
          <span
            v-else-if="type96 || voidVrm || noDvlaRegistration"
            class="has-text-danger"
            >Warning</span
          >
          <span class="has-text-success" v-else>None</span>
        </p>
      </div>
    </div>
    <div class="box" v-if="type96">
      <p class="title is-6 has-text-danger">
        <span class="icon">
          <i class="fal fa-exclamation-triangle" />
        </span>
        There is an issue with this check. Phone 0870 9059 095 to speak to an
        agent
      </p>
    </div>
    <div class="box" v-if="voidVrm">
      <p class="title is-6 has-text-danger">
        <span class="icon">
          <i class="fal fa-exclamation-triangle" />
        </span>
        Void VRM - check the new plate. The data guarantee is not applicable to
        this check.
      </p>
    </div>
    <div class="box" v-if="noDvlaRegistration">
      <p class="title is-6 has-text-danger">
        <span class="icon">
          <i class="fal fa-exclamation-triangle" />
        </span>
        There is no DVLA registration for this vehicle. The data guarantee is
        not applicable to this check.
      </p>
    </div>
    <div v-if="changes" class="box">
      <table class="table is-fullwidth is-striped">
        <thead>
          <tr>
            <td>Plate change</td>
            <td>Date of change</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td width="50%">
              <div class="columns is-vcentered is-mobile">
                <div class="column is-narrow">
                  <button class="button has-shadow is-size-7-mobile">
                    {{ item.previousVRM }}
                  </button>
                </div>
                <div class="column is-narrow is-paddingless">
                  <span class="icon has-text-info is-size-7-mobile">
                    <i class="fal fa-arrow-right" />
                  </span>
                </div>
                <div class="column is-narrow">
                  <button class="button has-shadow is-size-7-mobile">
                    {{ item.currentVRM }}
                  </button>
                </div>
              </div>
            </td>
            <td width="50%">{{ formatDate(item.dateOfReceipt) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckPlateChange',
  props: {
    data: {
      type: Object,
      required: true
    },
    type96: {
      type: Boolean,
      required: true
    },
    voidVrm: {
      type: Boolean,
      required: true
    },
    noDvlaRegistration: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    changes() {
      return this.data.plateChangeCount
    },
    items() {
      return this.data.plateChanges1
    }
  },
  methods: {
    formatDate
  }
}
</script>
